import {
  faBellSlash,
  faCircleExclamation,
  faBoxArchive,
  faTrashCan,
  faArrowRightArrowLeft,
  faRightLeft,
  faMagnifyingGlass,
  faCalendarCheck,
  faFileExcel,
  faCogs,
  faCreditCard,
  faGasPump,
  faLineChart,
  faLocationDot,
  faPlus,
  faRefresh,
  faRoad,
  faTint,
  faTruck,
  faTruckMoving,
  faUserTie,
  faClone,
  faBackward,
  faFloppyDisk,
  faCheck,
  faPen,
  faBan,
  faCheckCircle,
  faWarning,
  faTrash,
  faLaptopFile,
  faCircleInfo,
  faScrewdriverWrench,
  faClockRotateLeft,
  faRoute,
  faS,
  faScrewdriver
} from '@fortawesome/free-solid-svg-icons';

export const ESRI_APIKEY =
  'AAPK392a310f2a96449380cc087e6008b0d8no4SHm_DUPpEe_ZEkoiNzl9jWADLn5rjHQXw3z9tMiAHnhAjR-gYpelkkmuEmfSs';
/*EndPoints*/
export const LOGIN_ENDPOINT = '/account/authentication';
export const CURRENT_USER_ENDPOINT = '/users/current';
export const VERSION_BAG_ENDPOINT = '/platform/version';
export const PREFERENCE_ENDPOINT = '/common/preferences';
export const CHANGE_PASSWORD_ENDPOINT = '/account/password/change';
export const RESET_PASSWORD_ENDPOINT = '/account/password/askreset';
export const CONFIRM_RESET_PASSWORD_ENDPOINT = '/account/password/confirmreset';
export const ALERTS_BY_CUSTOMER_ENDPOINT = '/alerting/ofCustomer';
export const ALERTS_WARNING = '/warnings/';
export const Vehicules_BY_CUSTOMER_ENDPOINT = '/vehicles/ofCustomer';
export const DEVICE_GET_INST_TYPE = '/devices/getInstType/'
export const VEHICLE_ASSIGN_ENDPOINT = '/vehicles/assign/toCustomer';
export const VEHICLE_UNASSIGN_ENDPOINT = '/vehicles/unassign?uidVehicles=';
export const GEOFENCING_BY_CUSTOMER_ENDPOINT = '/geofencing/ofCustomer';
export const CUSTOMERS_BY_CUSTOMER_ENDPOINT = '/customers/ofCustomer';
export const USRES_BY_CUSTOMER_ENDPOINT = '/users/ofCustomer';
export const TEAMS_BY_CUSTOMER_ENDPOINT = '/teams/ofCustomer';
export const DRIVERSS_BY_CUSTOMER_ENDPOINT = '/myassets/drivers/ofCustomer';
export const INFRINGEMENTS_RECALC = '/infringements/recalc';
export const INFRINGEMENTS_MANIFEST = '/infringements/manifest';
export const GROUPS_BY_CUSTOMER_ENDPOINT = '/groups/ofCustomer';
export const REPORTS_TYPE_OFCUSTOMER = '/sched/reports/types/ofCustomer/';
export const REPORT_PLANNEE_OFCUSTOMER = '/sched/reports/planned/ofCustomer/';
export const REPORT = '/sched/report';
export const REPORT_OFCUSTOMER_UNDO = '/sched/report/ofCustomer/:uidCustomer/undo/:uidRequest';
export const REPORT_OFCUSTOMER = '/sched/reports/ofCustomer/:uidCustomer';
export const REPORT_DOWNLOAD = '/sched/report/download';
export const ALERTING_ENDPOINT = '/alerting';
export const ONE_USER_ENDPOINT = '/users/user';
export const ONE_TEAM_ENDPOINT = '/teams/team';
export const ONE_VEHICLE_ENDPOINT = '/vehicles/vehicle';
export const VEHICLES_DEVICE_INST_TYPE = '/vehicles/deviceInstType';
export const USERS_ENDPOINT = '/users';
export const TEAMS_ENDPOINT = '/teams';
export const USERS_GROUP_ENDPOINT = '/users/group';
export const USERS_ACTIVATION_EMAIL_ENDPOINT = '/users/sendActivationEmail';
export const USERS_LOCK_TOGGLE_ENDPOINT = '/users/lock/toggle';
export const ALERT_CHANNELS_ENDPOINT = '/common/alertingNotificationChannels';
export const FLEETS_OF_CUSTOMER_ENDPOINT = '/fleets/ofCustomer/';
export const USERS_OF_CUSTOMER_ENDPOINT = '/users/ofCustomer/';
export const FLEETS_ENDPOINT = '/fleets';
export const FLEETS_INFO_ENDPOINT = '/fleets/fleet';
export const SEARCH_DEVICES_ENDPOINT = '/devices/search';
export const FMWTOKEN_ENDPOINT = '/fmw/getRepoToken';
export const ONE_DEVICE_ENDPOINT = '/devices/device';
export const DEVICES_ENDPOINT = '/devices';
export const DEVICE_NOT = '/deviceNot';
export const REFUEL = '/refuel';
export const DEVICE_MODELS_ENDPOINT = '/common/deviceModels';
export const INSTALLATION_TYPE_ENDPOINT = '/common/installationTypes';
export const COUNTRIES_ENDPOINT = '/common/countries';
export const DEVICES_CARRIERS_ENDPOINT = '/common/deviceCarriers';
export const CONFIG_OF_DEVICES_ENDPOINT = '/devices/configuration/ofDevice';
export const DEVICES_HISTORY_ENDPOINT = '/devices/historicalBindingsOf';
export const DEVICES_REPORT_ENDPOINT = '/devices/report/ofDevice';
export const DEVICES_FMWHISTORY_OFDEVICE = '/devices/fmwHistory/ofDevice/';
export const DEVICES_STATUS_OFDEVICE = '/devices/status/ofDevice/';
export const DEVICES_SENDSMS_ENDPOINT = '/devices/sendSMS';
export const FIRMWARE_LIST_ENDPOINT = '/fmw/pckgs';
export const FIRMWARE_INFO_ENDPOINT = '/fmw/pckg';
export const DEVICE_MODELS_LIST_ENDPOINT = '/common/deviceModels';
export const ASSIGN_FIRMWARE_ENDPOINT = '/fmw/assign';
export const MYASSETS_FLEETS_OFCUSTOMER = '/myassets/fleets/ofCustomer/';
export const MYASSETS_TEAMS_OFCUSTOMER = '/myassets/teams/ofCustomer/';
export const MYASSETS_VEHICLES_OFCUSTOMER = '/myassets/vehicles/ofCustomer/';
export const MYASSETS_DRIVERS_OFCUSTOMER = '/myassets/drivers/ofCustomer/';
export const TRACKING_JOURNEY_ENDPOINT = '/tracking/journey';
export const TACHO_ACTIVITIES_ENDPOINT = '/tacho/cardData/driveractivities';
export const GET_VEHICLES_SEARCH = '/myassets/vehicles/search';
export const GET_DEVICES_SEARCH = '/myassets/devices/search';
export const GET_DRIVERS_SEARCH = '/myassets/drivers/search';
export const LOGINAS_OF_CUSTOMER = '/loginas/customer';
export const LOGINAS_USER = '/loginas/user';
export const VEHICLE_MOVE_ENDPOINT = '/vehicles/move/toCustomer';
export const MISCELLANEUS_ENDPOINT = '/miscellaneous';
export const DDD_EXTENSIONS_ENDPOINT = "/tacho/ddd/extensions?isTacho=";
export const TRAILERS_BY_CUSTOMER_ENDPOINT = '/etrailer/ofCustomer';
export const SAVE_TRAILERS_PAIRS = '/etrailer/save';
export const NEW_JOURNEY_DAILY = '/newJourney/daily';
export const DETAIL_NEW_JOURNEY_DAILY = '/newJourney/detail';
export const INSTALL = '/installation/install';
export const INSTALL_CUSTOMER = '/installation/customer';
export const INSTALLATION_STATE = '/installation/last';
export const HISTORICAL_INSTALLATION_STATE = '/installation/list';
export const INSTALLATION_ADMIN_LIST = '/installation/adminList/';
export const HISTORICAL_INSTALLATION_HISTORY = '/installation/history';
export const INFRINGEMENTS_RECALCREQUEST = '/tacho/infrigments/recalcRequest';


/* PowerBI Endpoints */
export const POWERBI_AUTH_ENDPOINT = '/powerbi/get-access-token/';

/**/
export const VEHICLE_TYPES_ENDPOINT = '/common/vehicles';
export const HIDE_VEHICLE_ENDPOINT = '/hide/toggle';
export const ENGINE_TYPES_ENDPOINT = '/common/engines';
export const INSTALLATIONS_TYPES_ENDPOINT = '/common/installations';
export const DEVICES_CONFIG_ENDPOINT = '/devices/configuration';
export const DRIVERS_BY_CUSTOMER_ENDPOINT = '/drivers/ofCustomer';
export const DRIVERS_NAMESAKE_BY_CUSTOMER_ENDPOINT =
  '/drivers/namesake/ofCustomer';
export const MERGE_NAMESAKE_ENDPOINT = '/drivers/namesake/merge';
export const DRIVERS_ASSIGN_ENDPOINT = '/drivers/assign/toCustomer';
export const DRIVERS_UNASSIGN_ENDPOINT = '/drivers/unassign';
export const DRIVER_FORCEDDD_ENDPOINT = '/drivers/driver/forceDDD';
export const Vehicle_FORCEDDD_ENDPOINT = '/vehicles/vehicle/forceDDD';
export const ONE_DRIVER_ENDPOINT = '/drivers/driver';
export const DRIVERS_MOVE_CUSTOMER_ENDPOINT = '/drivers/move/toCustomer';
export const ACCOUNT_ACTIVATE_ENDPOINT = '/account/activate';
export const ONE_GROUP_ENDPOINT = '/groups/group';
export const GROUPS_ENDPOINT = '/groups';
export const RECALC_REQUEST = '/tacho/infrigments/recalcRequest/';
export const FEATURES_CUSTOMER_ENDPOINT = '/features/ofCustomer';
export const ONE_CUSTOMER_ENDPOINT = '/customers/customer';
export const LEGISLATION_ENDPOINT = '/common/legislations';
export const DEFAULT_FMW_ENDPOINT = '/fmw/default/byCustomer';
export const HOMEPAGE_MAP_ENDPOINT = '/homepage/map';
export const ASSETS_DRIVER_CUSTOMER_ENDPOINT = '/myassets/drivers/ofCustomer';
export const STARTWEBCALL_ENDPOINT = '/webcall/startwebcall';
export const DDD_ENDPOINT = '/tacho/ddd';
export const DDD_CUSTOMER_ENDPOINT = '/tacho/ddd/byCustomerParent';
export const DOWNLOAD_FROM_ASSET = '/sched/downloadFromAsset';
export const SCHEDULING_REQUEST = '/sched/downloadFromAsset/ofCustomer';
export const DELETE_SCHEDULING = '/sched/downloadFromAsset/ofCustomer';
export const DISCHARGE_BALANCE = '/sched/downloadFromAsset/ofCustomer';
export const DDD_DOWNLOAD_ENDPOINT = '/tacho/ddd/download';
export const DDD_DOWNLOAD_ENDPOINT_REQUEST = '/tacho/ddd/download/requests?uidCustomer=';
export const DDD_HISTORY_ENDPOINT = '/upload/ddd/history';
export const CRDS_INFO = '/crds/crdsInfo';
export const CRSD_OFCUSTOMER = '/crds/ofCustomer/'
export const REMOVE_CARD_CRDS = '/crds/ofCustomer/';
export const REMOTE_PROCEDURES = '/crds/remoteProcedures';
export const DELETE_REMOTE_PROCEDURES = '/crds/ofCustomer/';
export const CRDS_REGISTRED_CARDS = '/crds/registeredCards';
export const TACHO_DAILY_ACTIVITIES_ENDPOINT = '/tacho/carddata/dailyDriverActivities';
export const TACHO_DAILY_REPORT_EXCEL = '/tacho/cardData/dailyDriverReportExcel';
export const TACHO_CARD_DATA = 'api/tacho/carddata/activities';
export const TACHO_LIST_BY_TEAM = '/api/teams';
export const TACHO_INFRINGEMENTS_DETAILS = '/tacho/infrigments';
export const TACHO_INFRINGEMENTS_LETTERS = '/print/tacho/infrigments/letters'

/*Patterns*/
export const EMAIL_PATTERN =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const PHONE_NUMBER_PATTERN = /[0-9]{1,20}/;

/* Icons */
export const FILE_EXCEL_ICON = faFileExcel;
export const REFRESH_ICON = faRefresh;
export const PLUS_ICON = faPlus;
export const CLONE_ICON = faClone;
export const RIGHT_LEFT_ICON = faRightLeft;
export const TRASH_ICON = faTrash;
export const BACK_ICON = faBackward;
export const FLOPPY_DISK_ICON = faFloppyDisk;
export const CHECK_ICON = faCheck;
export const PEN_ICON = faPen;
export const BAN_ICON = faBan;
export const CHECK_CIRCLE_ICON = faCheckCircle;
export const WARNING_ICON = faWarning;
export const SEARCH_ICON = faMagnifyingGlass;
export const DOUBLE_ARROW_ICON = faArrowRightArrowLeft;
export const BOX_ICON = faBoxArchive;
export const CIRCLE_EXCLAMATION_ICON = faCircleExclamation;
export const INFO_ICON = faCircleInfo;
export const SCREW_DRIVER_WRENCH = faScrewdriverWrench;

/* constants */
export const LAST_LIFE_SIGNAL = 3;
export const CACHE_KEY = 'stale';

/**/
export const ICON_STATE_MAPPER: any = {
  administration: faCogs,
  realtimeDriver: faRoad,
  tracking: faLocationDot,
  dashboardHistoricalLegalData: faLineChart,
  dashboardHistoricalConsumption: faTint,
  dashboardHistoricalFuelLevel: faGasPump,
  fuelColl: faGasPump,
  tacho: faTruck,
  timecard: faRoad,
  //timecard: faCreditCard,
  vehicleMaintenance: faCalendarCheck,
  dataUsage: faUserTie,
  crds: faLaptopFile,
  historyJourney: faRoute,
  installation: faScrewdriverWrench
};

export const ROUTER_STATE_MAPPER: any = {
  maintenanceOperations: '/app/maintenance/operations',
  maintenancePlans: '/app/maintenance/plans',
  maintenanceDeadlines: '/app/maintenance/deadlines',
  timecardLegaldata: '/app/timecard/legaldata',
  timecardRealtime: '/app/timecard/realtime',
  timecardOdometer: '/app/timecard/journeysodometer',
  activities: '/app/tacho/activitiesreport',
  vehiclesactivities: '/app/tacho/vehiclesactivitiesreport',
  dailyactivities: '/app/tacho/dailyactivities',
  dashboardHistoricalInfrigments: '/app/tacho/dashboardHistoricalInfrigments',
  infringements: '/app/tacho/infrigmentsreport',
  eventsandfaults: '/app/tacho/eventsfaults',
  vehiclesodometer: '/app/tacho/vehiclesodometer',
  downloaddeadline: '/app/tacho/downloaddeadline',
  vehiclesspeed: '/app/tacho/vehiclesspeedreport',
  dashboardHistoricalLegalData: '/app/dashboardHistoricalLegalData',
  dashboardHistoricalConsumption: '/app/fuel/dashboardHistoricalConsumption',
  dashboardHistoricalFuelLevel: '/app/fuel/dashboardHistoricalFuelLevel',
  refuelReport: '/app/fuel/refuelReport',
  assetMessages: '/app/statistics/messages_asset',
  audit: '/app/statistics/audit',
  silentDevices: '/app/statistics/silentdevices',
  alertManagement: '/app/manage/alert',
  fleets: '/app/fleets',
  users: '/app/manage/user',
  teams: '/app/manage/team',
  devices: '/app/manage/device',
  fmwpckgs: '/app/manage/firmware',
  deviceNot: 'app/manage/deviceNot',
  vehicles: '/app/manage/vehicles',
  devcfg: '/app/manage/deviceConfig',
  drivers: '/app/manage/drivers',
  trailers: '/app/manage/trailers',//#5556 FZ ADD NEW MENU ITEM FOR TRAILER
  userAppManagement : '/app/manage/userAppManagement/',
  groups: '/app/manage/groups',
  customers: '/app/manage/customers',
  realtimeDriver: '/app/realtime',
  loginas: '/app/manage/loginas',
  tracking: '/app/tracking',
  ddd: '/app/tacho/ddd',
  dddUpload: '/app/tacho/dddUpload',
  downloadOnDemand: '/app/tacho/downloadOnDemand',
  reportOnDemand:'/app/tacho/reportOnDemand',
  infringementReprocessing:'/app/tacho/infringementReprocessing',
  crds_info: '/app/crds/crds_info',
  remote_procedures: '/app/crds/remote_procedures',
  registered_cards: '/app/crds/registered_cards',
  historyJourney: '/app/historyJourney',
  installationEtrailer: '/app/installation/installationEtrailer',
  installationEdiallight: '/app/installation/installationEdiallight'
};

export const DEADLINE_TYPES: any = [{ name: 'Once' }, { name: 'Periodic' }];
